.main-about {
  margin: auto;
  font-size: 14px;

  img {
    width: 100%;
    margin: 25px 0 0;
  }
  p{
    margin: 0;
  }
}
