.user-agreement {
  text-align: left;
  font-family: "Rubik", sans-serif;
  width: 100%;
  max-width: 1445px;
  margin: auto;
  font-size: 14px;
  margin-top: 2rem;
}

.user-agreement p {
  font-size: 15px;
  padding: 0 0 20px 0;
  margin: 0;
}

.user-agreement ol li {
  line-height: 28px;
}

.user-agreement ol li:before {
  position: static;
  font-size: 25px;
}

.user-agreement ol li ol li:before {
  font-size: 15px;
  line-height: 21px;
}

.user-agreement ol {
  list-style: none;
  counter-reset: cupcake;
}

.user-agreement ol li {
  counter-increment: cupcake;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  padding-left: 13px;
  position: relative;
  font-size: 24px;
  line-height: 23px;
  margin-bottom: 50px;
}

.user-agreement ol li ol {
  margin-top: 30px;
}

.user-agreement ol li ol li {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  counter-increment: cupcake;
  margin-bottom: 30px;
  padding-left: 15px;
  font-size: 15px;
  line-height: 20px;
}

.user-agreement ol li:before {
  content: counters(cupcake, ".") ". ";
  position: absolute;
  left: -45px;
  top: 2px;
  font-size: 14px;
  line-height: 28px;
}

.user-agreement ol li ol li:before {
  content: counters(cupcake, ".") " ";
  font-size: 15px;
  line-height: 16px;
}

.user-agreement ol li:before {
  position: static;
  font-size: 25px;
}

.user-agreement > ol > li ol > li:before {
  position: absolute;
  left: -40px;
}
