.contact-us-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.contact-us-half {
  width: 47%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.contact-us-half {
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

.contact-us-half.withBg {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  background-clip: padding-box;
  overflow: hidden;
  position: relative;
}
.input-field label {
  font-size: 15px;
  color: #000;
  letter-spacing: -0.4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 3px 0;
  display: block;
  font-weight: 500;
}
.input-field input,
.input-field textarea {
  height: 45px;
  border: 1px solid #e1e1e1;
  background: #fff;
  border-radius: 10px;
  padding: 5px 15px;
  color: #000;
  transition: all 0.2s ease;
  font-size: 15px;
  width: 100%;
  font-family: "Rubik", sans-serif;
  margin-bottom: 12px;
}
.input-field textarea {
  height: 120px;
  padding: 15px;
}
.btn-section {
  margin-top: 10px;
}
.btn-section .btn-contact-us {
  background-color: rgb(70, 40, 249);
  color: #fff;
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.3s ease-in-out 0s;
  font-weight: 500;
  line-height: 3rem;
  font-family: "Rubik", sans-serif;
  width: 180px;
  height: 50px;
  font-size: 14px;

  &:disabled {
    opacity: 0.5;
  }
}

.contact-us-half .contact-us-details {
  height: auto;
  max-height: 50%;
  padding: 3rem 45px;
  width: 100%;
}
.contact-us-half .contact-us-details .contact-address {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 50px 20px 0;
  font-size: 15px;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
  color: #000;
  cursor: pointer;
}
.contact-us-half .contact-us-details .contact-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.contact-us-half .contact-us-details .contact-title {
  font-size: 20px;
  padding: 0;
  margin: 0 0 5px 0;
}
.contact-us-half .contact-us-details .contact-links li {
  width: 50%;
  list-style: none;
}
.contact-us-half .contact-us-details .contact-links li .icon-call-number,
.contact-us-half .contact-us-details .contact-links li .icon-email-address {
  color: #1f1f1f;
  font-size: 18px;
  position: relative;
  display: inline-block;
  letter-spacing: -0.9px;
  line-height: 20px;
  text-decoration: none;
  font-weight: 700;
}
.contact-us-half .contact-us-details .contact-links li .icon-call-number span,
.contact-us-half
  .contact-us-details
  .contact-links
  li
  .icon-email-address
  span {
  display: block;
  font-size: 15px;
  color: #000;
  letter-spacing: -0.3px;
  font-weight: 400;
}
