.privacy-policy {
  text-align: left;
  font-family: "Rubik", sans-serif;
  width: 100%;
  max-width: 1445px;
  margin: auto;
  font-size: 14px;
  margin-top: 2rem;
}

.privacy-policy p {
  font-size: 15px;
  padding: 0 0 20px 0;
  margin: 0;
}
.privacy-policy ol li {
  line-height: 28px;
}

.privacy-policy ol li:before {
  position: static;
  font-size: 25px;
}

.privacy-policy ol li ol li:before {
  font-size: 15px;
  line-height: 21px;
}

.privacy-policy ol {
  list-style: none;
  counter-reset: cupcake;
}

.privacy-policy ol li {
  counter-increment: cupcake;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  padding-left: 13px;
  position: relative;
  font-size: 24px;
  line-height: 23px;
  margin-bottom: 50px;
}

.privacy-policy ol li ol {
  margin-top: 30px;
}

.privacy-policy ol li ol li {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  counter-increment: cupcake;
  margin-bottom: 30px;
  padding-left: 0;
  font-size: 15px;
  line-height: 20px;
}

.privacy-policy ol li:before {
  content: counters(cupcake, ".") ". ";
  position: absolute;
  left: -45px;
  top: 2px;
  font-size: 14px;
  line-height: 28px;
}

.privacy-policy ol li ol li:before {
  content: counters(cupcake, ".") " ";
  font-size: 15px;
  line-height: 15px;
}
.privacy-policy ol li:before {
  position: static;
  font-size: 25px;
}
.privacy-policy > ol > li ol > li:before {
  position: absolute;
  left: -40px;
}
