.faq-row {
  margin-bottom: 24px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
}

.faq-title {
  letter-spacing: -0.4px;
  margin: 0 0 9px;
  font-size: 22px;
  font-weight: 700;
}

.faqs {
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.faqs,
.faqs li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.faqs .question {
  letter-spacing: -0.4px;
  margin: 0 0 5px;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.faqs li {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 48%;
  margin-bottom: 20px;
}

.faq-row p {
  line-height: 22px;
  padding: 0;
  margin: 0;
}
.faq-row.bb-0 {
  border: 0 !important;
  margin-bottom: 30px;
}
